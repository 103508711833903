import axios from "axios";
import { getItemIDB, setItemIDB, clearIDB } from "../indexedDb";
import { refreshSession, signOut } from "../../libs/cognito";

const baseURL = process.env.REACT_APP_API_BASE_URL;
const port = process.env.REACT_APP_API_PORT;
const environment = process.env.NODE_ENV;
export const apiURL = environment !== "development" ? `https://${baseURL}` : `${baseURL}:${port}`;

const getCookies = () =>
  document.cookie.split("; ").reduce((acc, cookie) => {
    const [name, value] = cookie.split("=");
    acc[name] = value;
    return acc;
  }, {} as Record<string, string>);

const instance = axios.create({
  baseURL: apiURL,
  headers: {
    "Content-Type": "application/json",
    Authorization: "",
  },
  withCredentials: true,
});

const signOutUser = () => {
  signOut();
  clearIDB().catch((e) => console.error(e));
  localStorage.clear();
  window.location.replace("/");
};

const requestInterceptor = async (config: any) => {
  const token = await getItemIDB("idToken");
  config.headers.Authorization = `Bearer ${token || null}`;
  const cookies = getCookies();
  config.headers["x-fbc"] = cookies["_fbc"];
  config.headers["x-fbp"] = cookies["_fbp"];
  return config;
};

instance.interceptors.request.use(requestInterceptor);

// Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    // Refresh Cognito Token and re-try the request once
    // If it fails sign out the user
    if (error.response.status === 401 && !originalRequest._retry) {
      const currentRefreshToken = await getItemIDB("refreshToken");
      if (!currentRefreshToken) {
        signOutUser();
      }

      const { idToken, refreshToken } = (await refreshSession(currentRefreshToken)) as {
        idToken: { jwtToken: string };
        refreshToken: { token: string };
      };

      // Update the refreshToken as well
      if (refreshToken && refreshToken.token) {
        await setItemIDB("refreshToken", refreshToken.token);
      }

      if (idToken && idToken.jwtToken) {
        await setItemIDB("idToken", idToken.jwtToken);
        originalRequest._retry = true;
        axios.defaults.headers.common["Authorization"] = "Bearer " + idToken.jwtToken;
        return instance(originalRequest);
      } else {
        signOutUser();
      }
    } else if (error.response.status === 401 && originalRequest._retry === true) {
      signOutUser();
    }

    // Handle 50x errors on /users/me
    // Try making the request one more time
    // And if it fails again sign out the user
    if (error.response.status >= 500 && originalRequest.url === "/users/me" && !originalRequest._retry) {
      originalRequest._retry = true;
      return instance(originalRequest);
    } else if (error.response.status >= 500 && originalRequest.url === "/users/me" && originalRequest._retry === true) {
      signOutUser();
    }

    // Handle 404 error on /users/me
    // Sign out the user as there's no data in the database
    if (error.response.status === 404 && originalRequest.url === "/users/me") {
      signOutUser();
    }

    return Promise.reject(error);
  }
);

export default instance;
